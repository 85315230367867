export const ApiQueryKey = {
	productTypes: 'product-types',

	formTemplates: 'form-templates',
	forms: 'forms',

	admins: 'admins',
	loginHistory: 'logins-history',

	content: 'content',
	roles: 'roles',
	globalSettings: 'global-settings',
} as const;

export type ApiQueryKey = (typeof ApiQueryKey)[keyof typeof ApiQueryKey];
