import { Attachment, UUID } from '@/types/types';
import { ApiQueryKey } from '@/configs/ApiQueryKey';
import { FormTemplate } from './FormTemplateApi';
import { CreateProductTypeForm } from '@/pages/authenticated/productTypes/CreateProductType';
import { EditProductTypeForm } from '@/pages/authenticated/productTypes/EditProductType';
import { blankProductTypeContent } from '@/utils/utilities';
import httpClient from './httpClient';
import EntityApi from './EntityApi';
import AppApi from './AppApi';

export enum SectionType {
	CARDS = 'CARDS',
	LISTS = 'LISTS',
	ARTICLES = 'ARTICLES',
}

interface ProductTypeSection {
	title: string;
	description: string;
}

export interface ProductTypeSectionCard extends ProductTypeSection {
	cards: { title: string; description: string }[];
}

export interface ProductTypeSectionLists extends ProductTypeSection {
	lists: { title: string; entries: string[] }[];
}

export interface ProductTypeSectionArticles extends ProductTypeSection {
	articles: { title: string; description: string; image: Attachment | null }[];
}

export interface PredefinedDimension {
	width: number;
	height: number;
	depth: number;
	minQuantity: number;
}

export interface ProductType {
	id: UUID;
	name: string;
	description: string;
	image?: Attachment;
	formTemplate?: FormTemplate;
	listOrder: number;
	isPerDimensionQuantityEnabled: boolean;
	predefinedDimensions: PredefinedDimension[];
	minCustomDimensionQuantity: number;
	content: {
		[SectionType.CARDS]: ProductTypeSectionCard;
		[SectionType.LISTS]: ProductTypeSectionLists;
		[SectionType.ARTICLES]: ProductTypeSectionArticles;
	};
	isHidden: boolean;
}

class ProductTypeApi extends EntityApi<ProductType> {
	constructor() {
		super(ApiQueryKey.productTypes);
		this.createWithImage = this.createWithImage.bind(this);
		this.updateWithImage = this.updateWithImage.bind(this);
	}

	async createWithImage(payload: CreateProductTypeForm) {
		let image: Attachment | undefined;

		if (payload.file) image = await AppApi.createAttachment(payload.file);

		const response = await httpClient.post<ProductType>(this.queryKey, {
			...payload,
			image,
			content: blankProductTypeContent(),
		});

		return response.data;
	}

	async updateWithImage(payload: EditProductTypeForm) {
		let image: Attachment | undefined;

		if (payload.file) image = await AppApi.createAttachment(payload.file);

		const response = await httpClient.patch<ProductType>(
			`${this.queryKey}/${payload.id}`,
			{
				...payload,
				image,
			}
		);
		return response.data;
	}
}

export default new ProductTypeApi();
